/**
 * Auth Routes
 */

const authRoutes = [
    {
        path: "/auth",
        name: "auth",
        redirect: { name: "auth" },
        component: () => import("@/layouts/auth/index.vue"),
        children: [
            {
                path: "/login",
                name: "auth.login",
                component: () => import("@/views/auth/login.vue"),
                meta: {
                    auth: "block",
                },
            },
            {
                path: "/register",
                name: "auth.register",
                component: () => import("@/views/auth/register.vue"),
                meta: {
                    auth: false,
                },
            },
            {
                path: "/for-approval",
                name: "auth.for-approval",
                component: () => import("@/views/auth/for-approval.vue"),
                meta: {
                    auth: true,
                },
            },
            {
                path: "/forgot-password",
                name: "auth.forgot-password",
                component: () => import("@/views/auth/forgot-password.vue"),
                meta: {
                    auth: false,
                },
            },
            {
                path: "/reset-password",
                name: "auth.reset-password",
                component: () => import("@/views/auth/reset-password.vue"),
                meta: {
                    auth: false,
                },
            },
        ],
        meta: {
            auth: "block",
        },
    },
    {
        path: "/logout",
        name: "auth.logout",
        component: () => import("@/views/auth/logout.vue"),
        meta: {
            auth: true,
        },
    },
];

export default authRoutes;
