/**
 * Global router configuration.
 */

import Vue from "vue";
import VueRouter from "vue-router";
import AuthRoutes from "./modules/auth-routes";
import PanelRoutes from "./modules/panel-routes";
import PublicRoutes from "./modules/public-routes";
import SystemRoutes from "./modules/system-routes";
import StyleRoutes from "./modules/styles-routes";
import AccountMiddleware from "./middleware/account-middleware";
import PermissionMiddleware from "./middleware/permission-middleware";

Vue.use(VueRouter);

/**
 * Arrange the middleware that the router executes on route changes.
 * These are executed in order.
 */
const routerMiddleware = [
    AccountMiddleware,
    PermissionMiddleware,
];

const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            redirect: { name: "auth.login" },
            meta: {
                auth: true,
            },
        },

        ...AuthRoutes,
        ...PanelRoutes,
        ...PublicRoutes,
        ...SystemRoutes,
        ...StyleRoutes,
    ],
});

routerMiddleware.forEach((middleware) => {
    middleware(router);
});

export default router;
