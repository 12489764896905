import MeService from "@/services/modules/me-service";
import { createModule } from "@/store/base-module";

export default createModule({
    service: MeService,
    itemKey: "user_id",

    actions: {
        updateProfile({ commit }, payload = {}) {
            return new Promise((resolve, reject) => {
                const handleSuccess = (response) => {
                    commit("updateProfile", response.data);
                    resolve(response);
                };

                const handleFailure = (error) => {
                    reject(error);
                };

                MeService.updateProfile(payload)
                    .then(handleSuccess)
                    .catch(handleFailure);
            });
        },
        updateSettings({ commit }, payload = {}) {
            return new Promise((resolve, reject) => {
                const handleSuccess = (response) => {
                    commit("updateSettings", response.data);
                    resolve(response);
                };

                const handleFailure = (error) => {
                    reject(error);
                };

                MeService.updateSettings(payload)
                    .then(handleSuccess)
                    .catch(handleFailure);
            });
        },
    },
});
