import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./lang";
import App from "./App.vue";
import Router from "./router";
import Store from "./store";
import "./register-components";
import "./register-plugins";
import "./register-filters";
import "./assets/scss/app.scss";

Vue.config.productionTip = false;

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: "en",
    messages,
});

new Vue({
    router: Router,
    store: Store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
