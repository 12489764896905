import BaseService from "../base-service";

class NoteService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "notes",
        });
    }
}

export default new NoteService();
