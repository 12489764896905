/**
 * Orders Routes
 */

const ordersRoutes = [
    {
        path: "/orders",
        name: "orders",
        redirect: { name: "orders.index" },
        component: () => import("@/components/ghost.vue"),
        meta: {
            auth: true,
            breadcrumb: true,
            title: "Orders",
            permissions: [
                "orders.read",
            ],
        },
        children: [
            {
                path: "/orders",
                name: "orders.index",
                component: () => import("@/views/panel/orders/index.vue"),
                meta: {
                    auth: true,
                    title: "Orders",
                },
            },
            {
                path: ":order/show",
                name: "orders.show",
                redirect: { name: "orders.show.summary" },
                component: () => import("@/views/panel/orders/show/index.vue"),
                meta: {
                    auth: true,
                    breadcrumb: true,
                    title: (route) => new Promise((resolve) => {
                        resolve(route.params.order);
                    }),
                },
                children: [
                    {
                        path: "summary",
                        name: "orders.show.summary",
                        component: () => import("@/views/panel/orders/show/summary.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Order Details",
                        },
                    },
                    // {
                    //     path: "order-fees",
                    //     name: "orders.show.order-fees",
                    //     component: () => import("@/views/panel/orders/show/order-fees.vue"),
                    //     meta: {
                    //         auth: true,
                    //         breadcrumb: true,
                    //         title: "Order Fees",
                    //         permissions: [
                    //             "order-fees.read",
                    //         ],
                    //     },
                    // },
                    // {
                    //     path: "jobs",
                    //     name: "orders.show.jobs",
                    //     component: () => import("@/views/panel/orders/show/jobs.vue"),
                    //     meta: {
                    //         auth: true,
                    //         breadcrumb: true,
                    //         title: "Jobs",
                    //     },
                    // },
                ],
            },
        ],
    },
];

export default ordersRoutes;
