<template>
    <v-ghost />
</template>

<script>
export default {
    watch: {
        "$route.meta": {
            handler(meta) {
                if (meta.title) {
                    if (typeof meta.title === "function") {
                        document.title = `${meta.title(this.$route) } - Sherpas of Destiny`;
                        return;
                    }

                    if (typeof meta.title === "string") {
                        document.title = `${meta.title } - Sherpas of Destiny`;
                        return;
                    }

                    document.title = "Page Title";
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>
