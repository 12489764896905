import BaseService from "../base-service";

class OrderLineService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "order-lines",
        });
    }
}

export default new OrderLineService();
