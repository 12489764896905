/**
 * Checks the user's accounts if they are allowed
 * to view the page.
 */

import Vue from "vue";

const checkAccount = (to, from, next) => {
    if (!Vue.prototype.$me.getUser().approved_at && !to.name.includes("auth")) {
        next({ name: "auth.for-approval" });
    } else {
        next();
    }

    next();
};

const accountMiddleware = (router) => {
    router.beforeEach((to, from, next) => {
        /**
         * Check if the route requires authentication.
         */
        const requiresAuth = (to.meta.auth === true) || (to.meta.auth === "block");

        /**
          * If the route requires authentication, check if the user is in the Vuex store.
          * Refresh the data if it's not there. Otherwise, do nothing.
          */
        if (!requiresAuth || to.name.includes("auth") || to.name.includes("system")) {
            next();
        } else if (!Vue.prototype.$me.getUser()) {
            Vue.prototype.$me.fetchUser()
                .then(() => {
                    checkAccount(to, from, next);
                })
                .catch(() => {
                    next({ name: "system.error", params: { code: 500 } });
                });
        } else {
            checkAccount(to, from, next);
        }
    });
};

export default accountMiddleware;
