import Vue from "vue";

const components = {
    "v-badge": () => import("./components/badge.vue"),
    "v-heading": () => import("./components/heading.vue"),
    "v-icon": () => import("./components/icon.vue"),
    "v-paragraph": () => import("./components/paragraph.vue"),
    "v-button": () => import("./components/button.vue"),
    "v-text": () => import("./components/text.vue"),
    "v-link": () => import("./components/link.vue"),
    "v-container": () => import("./components/container.vue"),
    "v-card": () => import("./components/card.vue"),
    "v-card-content": () => import("./components/card-content.vue"),
    "v-card-footer": () => import("./components/card-footer.vue"),
    "v-card-header": () => import("./components/card-header.vue"),
    "v-alert": () => import("./components/alert.vue"),
    "v-table-container": () => import("./components/table-container.vue"),
    "v-table": () => import("./components/table.vue"),
    "v-table-head": () => import("./components/table-head.vue"),
    "v-table-header": () => import("./components/table-header.vue"),
    "v-table-body": () => import("./components/table-body.vue"),
    "v-table-data": () => import("./components/table-data.vue"),
    "v-table-row": () => import("./components/table-row.vue"),
    "v-table-row-no-results": () => import("./components/table-row-no-results.vue"),
    "v-form-container": () => import("./components/form/container.vue"),
    "v-form-label": () => import("./components/form/label.vue"),
    "v-form": () => import("./components/form/form.vue"),
    "v-form-group": () => import("./components/form/group.vue"),
    "v-form-input": () => import("./components/form/input.vue"),
    "v-form-select": () => import("./components/form/select.vue"),
    "v-form-header": () => import("./components/form/header.vue"),
    "v-form-textarea": () => import("./components/form/textarea.vue"),
    "v-form-radio": () => import("./components/form/radio.vue"),
    "v-form-checkbox": () => import("./components/form/checkbox.vue"),
    "v-form-date": () => import("./components/form/date.vue"),
    "v-loader": () => import("./components/application/loader.vue"),
    "v-main": () => import("./layouts/panel/main.vue"),
    "v-section-content": () => import("./components/section-content.vue"),
    "v-section-group": () => import("./components/section-group.vue"),
    "v-section-divider": () => import("./layouts/panel/section-divider.vue"),
    "v-section-heading": () => import("./layouts/panel/section-heading.vue"),
    "v-section-toolbar": () => import("./components/section-toolbar.vue"),
    "v-section-links": () => import("./components/section-links.vue"),
    "v-section": () => import("./components/section.vue"),
    "v-slide-over": () => import("./components/slide-over.vue"),
    "v-modal": () => import("./components/modal.vue"),
    // "v-space-x": () => import("./components/space-x.vue"),

    "v-menu-sidebar": () => import("./layouts/panel/fragments/menu-sidebar.vue"),
    "v-vertical-menu": () => import("./components/vertical-menu.vue"),
    "v-vertical-menu-header": () => import("./components/vertical-menu-header.vue"),
    "v-vertical-menu-item": () => import("./components/vertical-menu-item.vue"),
    "v-accordion": () => import("./components/accordion.vue"),
    "v-accordion-item": () => import("./components/accordion-item.vue"),

    // "v-chart-bar": () => import("./components/chart-bar.vue"),
    // "v-chart-container": () => import("./components/chart-container.vue"),

    // Application Components
    "v-model-form": () => import("@/components/application/model-form.vue"),
    "v-model-select": () => import("@/components/application/model-select.vue"),
    "v-model-table": () => import("@/components/application/model-table.vue"),

    "v-ghost": () => import("@/components/ghost.vue"),

    "v-application-alerts": () => import("@/components/application/alerts.vue"),
};

Object.keys(components).forEach((key) => {
    Vue.component(key, components[key]);
});
