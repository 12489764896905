import BaseService from "../base-service";

class PayoutService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "payout-lines",
        });
    }
}

export default new PayoutService();
