/**
 * Panel Routes
 *
 * Application routes that are for auth protected areas.
 */

import OrdersRoutes from "./orders";
import JobsRoutes from "./jobs";
import AnalyticsRoutes from "./analytics";
import RolesRoutes from "./roles";
import UsersRoutes from "./users";
import PayoutsRoutes from "./payouts";
import MeRoutes from "./me";
import ConversationsRoutes from "./conversations";
import ReportsRoutes from "./reports";
import ConversationCommandsRoutes from "./conversation-commands";

const panelRoutes = [
    {
        path: "/panel",
        name: "panel",
        redirect: { name: "panel.dashboard" },
        component: () => import("@/layouts/panel/index.vue"),
        children: [
            {
                path: "/dashboard",
                name: "panel.dashboard",
                component: () => import("@/views/panel/dashboard/index.vue"),
                meta: {
                    auth: true,
                    title: "Dashboard",
                    breadcrumb: "Dashboard",
                },
            },

            ...OrdersRoutes,
            ...JobsRoutes,
            ...AnalyticsRoutes,
            ...RolesRoutes,
            ...UsersRoutes,
            ...PayoutsRoutes,
            ...MeRoutes,
            ...ConversationsRoutes,
            ...ReportsRoutes,
            ...ConversationCommandsRoutes,
        ],
    },
];

export default panelRoutes;
