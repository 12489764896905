import BaseService from "../../base-service";

class GameService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "example-items",
        });
    }
}

export default new GameService();
