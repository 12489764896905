/**
 * Public Routes
 */

const authRoutes = [
    {
        path: "/public",
        name: "public",
        redirect: { name: "public" },
        component: () => import("@/layouts/public/index.vue"),
        children: [
            {
                path: "notifications/:notificationType",
                name: "public.notifications.show",
                component: () => import("@/views/public/index.vue"),
                meta: {
                    auth: false,
                    title: "Notifications",
                },
                children: [
                    {
                        path: "unsubscribed",
                        name: "public.notifcations.show.unsubscribed",
                        component: () => import("@/views/public/notifications/unsubscribed.vue"),
                        meta: {
                            auth: false,
                            title: "Unsubscribed",
                        },
                    },
                ],
            },
            {
                path: "/conversation-commands/tip/:authToken",
                name: "public.commands.tip",
                component: () => import("@/views/public/commands/tip.vue"),
                meta: {
                    auth: false,
                    title: "Tip Sherpa",
                },
            },
        ],
        meta: {
            auth: false,
        },
    },
];

export default authRoutes;
