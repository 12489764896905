/**
 * The global request client for Vue.js components.
 */

import Axios from "axios";
import { EventBusInstance } from "@/plugins/event-bus";

const requestClient = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 10000,
});

requestClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("impersonation_token") ?? localStorage.getItem("access_token");
        const auth = token ? `Bearer ${token}` : "";
        config.headers.common.Authorization = auth;

        const gameId = localStorage.getItem("app:selectedGame");

        if (gameId && gameId !== "null" && gameId !== null) {
            config.params = {
                ...config.params,
                game_id: gameId,
            };
        }

        return config;
    },
    (error) => Promise.reject(error),
);

requestClient.interceptors.response.use((response) => {
    EventBusInstance.$emit("response", response);
    return response;
}, (error) => {
    if (error && error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login";
        console.error("Attempted to access unauthorized resource, logging out.");
        return Promise.reject(error);
    }

    if (error && error.response && error.response.status === 403) {
        window.location.href = "/error/403";
        return Promise.reject(error);
    }

    if (error && error.response && error.response.status === 423) {
        window.location.href = "/for-approval";
        return Promise.reject(error);
    }

    EventBusInstance.$emit("error", error);
    return Promise.reject(error);
});

export {
    requestClient,
};

export default requestClient;
