import Vue from "vue";

/**
 * Generate the default state.
 *
 * @returns { Object }
 */
const getDefaultState = () => ({
    menu: [],
    settings: {
        selectedGame: localStorage.getItem("app:selectedGame") ?? null,
    },
});

/**
 * Declare the module state.
 */

const state = getDefaultState();
/**
 * Declare the module getters.
 */
const getters = {
    getMenuItems(currentState) {
        return currentState.menu;
    },
    getSettings(currentState) {
        return currentState.settings;
    },
};

/**
 * Declare the module mutations.
 */
const mutations = {
    setMenuItems(currentState, items = []) {
        Vue.set(currentState, "menu", items);
    },
    setSetting(currentState, { key, value }) {
        Vue.set(currentState.settings, key, value);
        localStorage.setItem(`app:${key}`, value);
    },
};

/**
 * Declare the module actions.
 */
const actions = {
    resetApplication({ commit }) {
        return new Promise((resolve, reject) => {
            try {
                localStorage.clear();

                const modules = [
                    "authModule",
                    "gameModule",
                    "activityLogModule",
                    "gamePlatformModule",
                    "importOrderModule",
                    "importStatusModule",
                    "importSourceModule",
                    "importTypeModule",
                    "jobModule",
                    "meModule",
                    "noteModule",
                    "orderModule",
                    "orderLineModule",
                    "orderLineStatusModule",
                    "orderLineStatusHistoryModule",
                    "orderLineUserRelationshipTypeModule",
                    "orderStatusModule",
                    "orderStatusHistoryModule",
                    "orderUserModule",
                    "playerEnvironmentModule",
                    "productModule",
                    "payoutModule",
                    "payoutLineModule",
                    "payoutStatusModule",
                    "permissionModule",
                    "roleModule",
                    "userModule",
                    "userLoginModule",
                    "userTypeModule",
                ];

                modules.forEach((module) => {
                    commit(`${module}/resetState`, null, { root: true });
                });

                resolve();
            } catch (error) {
                reject(error);
            }
        });
    },
};

export default {
    namespaced: true,
    strict: true,
    state,
    getters,
    mutations,
    actions,
};
