import Vue from "vue";

const EventBusInstance = new Vue();

const eventBus = {
    install: () => {
        Vue.prototype.$eventBus = EventBusInstance;
    },
};

export default eventBus;

export {
    EventBusInstance,
};
