import RequestClient from "./request-client";

export default class BaseService {
    /**
     * Create a new BaseService instance.
     */
    constructor(options = {}) {
        const defaultOptions = {
            endpoint: "",
        };

        this.options = {
            ...defaultOptions,
            ...options,
        };

        this.requestClient = RequestClient;
    }

    /**
     * Request to retrieve all resources.
     */
    index(payload = {}) {
        return this.requestClient.get(`/api/${this.options.endpoint}`, { params: payload });
    }

    /**
     * Request to retrieve a specified resource.
     */
    show(id, payload = {}) {
        return this.requestClient.get(`/api/${this.options.endpoint}/${id}`, { params: payload });
    }

    /**
     * Request to create a new resource.
     */
    store(payload = {}) {
        return this.requestClient.post(`/api/${this.options.endpoint}`, payload);
    }

    /**
     * Request to update a specified resource.
     */
    update(id, payload = {}) {
        return this.requestClient.put(`/api/${this.options.endpoint}/${id}`, payload);
    }

    /**
     * Request to delete a specified resource.
     */
    delete(id, payload = {}) {
        return this.requestClient.delete(`/api/${this.options.endpoint}/${id}`, payload);
    }
}
