/**
 * Jobs Routes
 */

const jobsRoutes = [
    {
        path: "/jobs",
        name: "jobs",
        redirect: { name: "jobs.index" },
        component: () => import("@/components/ghost.vue"),
        meta: {
            auth: true,
            title: "Jobs",
            breadcrumb: true,
            permissions: [
                "order-lines.read",
            ],
        },

        children: [
            {
                path: "/jobs",
                name: "jobs.index",
                component: () => import("@/views/panel/jobs/index.vue"),
                meta: {
                    auth: true,
                    title: "Jobs",
                },
            },
            {
                path: ":job/show",
                name: "jobs.show",
                redirect: { name: "jobs.show.summary" },
                component: () => import("@/views/panel/jobs/show/index.vue"),
                meta: {
                    auth: true,
                    breadcrumb: true,
                    title: (route) => new Promise((resolve) => {
                        resolve(route.params.job);
                    }),
                },
                children: [
                    {
                        path: "summary",
                        name: "jobs.show.summary",
                        component: () => import("@/views/panel/jobs/show/summary.vue"),
                        meta: {
                            auth: true,
                            title: "Summary",
                            breadcrumb: true,
                        },
                    },
                ],
            },
        ],
    },
];

export default jobsRoutes;
