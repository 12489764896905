/**
 * Analytics Routes
 */

const analyticsRoutes = [
    {
        path: "/analytics",
        name: "analytics",
        redirect: { name: "analytics.index" },
        component: () => import("@/components/ghost.vue"),
        meta: {
            auth: true,
            breadcrumb: "Analytics",
        },

        children: [
            {
                path: "/analytics",
                name: "analytics.index",
                component: () => import("@/views/panel/analytics/index.vue"),
                meta: {
                    auth: true,
                },
            },
        ],
    },
];

export default analyticsRoutes;
