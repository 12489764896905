import BaseService from "../base-service";
import RequestClient from "../request-client";

class PayoutService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "jobs",
        });
    }

    getUsers(id, payload) {
        return this.requestClient.get(`/api/jobs/${id}/users`, { params: payload });
    }

    getAssignableUsers(id, payload) {
        return this.requestClient.get(`/api/jobs/${id}/assignable-users`, { params: payload });
    }

    getMetaInformation(id, payload) {
        return this.requestClient.get(`/api/jobs/${id}/get-meta-information`, { params: payload });
    }

    getOrderLineFees(id, payload) {
        return this.requestClient.get(`/api/jobs/${id}/get-order-line-fees`, { params: payload });
    }

    getOrder(id, payload) {
        return this.requestClient.get(`/api/jobs/${id}/get-order`, { params: payload });
    }

    getCustomer(id, payload) {
        return this.requestClient.get(`/api/jobs/${id}/get-customer`, { params: payload });
    }

    addAssistant({ id, payload }) {
        return this.requestClient.post(`/api/jobs/${id}/add-assistant`, payload);
    }

    removeAssistant({ id, payload }) {
        return this.requestClient.patch(`/api/jobs/${id}/remove-assistant`, payload);
    }

    makePrimary({ id, payload }) {
        return this.requestClient.patch(`/api/jobs/${id}/make-primary`, payload);
    }

    takeJob(id) {
        return RequestClient.put(`/api/jobs/${id}/take-job`);
    }

    untakeJob(id) {
        return RequestClient.put(`/api/jobs/${id}/untake-job`);
    }

    startJob(id) {
        return RequestClient.put(`/api/jobs/${id}/start-job`);
    }

    completeJob(id) {
        return RequestClient.put(`/api/jobs/${id}/complete-job`);
    }
}

export default new PayoutService();
