/**
 * Base Vuex Module
 */

import Vue from "vue";

const generateState = (state, itemKey) => ({
    ...state,
    items: [],
    selectedItem: null,
    itemKey,
});

const generateGetters = (getters) => ({
    ...getters,
    getItems(currentState) {
        return currentState.items;
    },
    getSelectedItem(currentState) {
        return currentState.selectedItem;
    },
});

const generateMutations = (mutations, itemKey) => ({
    ...mutations,
    resetState(currentState) {
        Object.assign(currentState, generateState());
    },
    setItems(currentState, items = []) {
        Vue.set(currentState, "items", items);
    },
    setSelectedItem(currentState, item = null) {
        Vue.set(currentState, "selectedItem", item);
    },
    updateItem(currentState, item = null) {
        const itemIndex = currentState.items.data
            ? currentState.items.data.findIndex((currentStateItem) => currentStateItem[itemKey] === item[itemKey])
            : null;

        if (currentState.items.data && itemKey !== null && itemIndex !== -1) {
            const oldItem = currentState.items.data[itemIndex];

            Vue.set(currentState.items.data, itemIndex, {
                ...oldItem,
                ...item,
            });
        }
    },
    storeItem(currentState, item = null) {
        if (!item) return;
        currentState.items.data.unshift(item);
    },
});

const generateActions = (actions, service) => ({
    ...actions,
    resetState({ commit }) {
        commit("resetState");
    },
    fetchAllItems: ({ commit }, payload = {}) => new Promise((resolve, reject) => {
        service.index(payload)
            .then(({ data }) => {
                commit("setItems", data);
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
    }),
    fetchOneItem: ({ commit }, { id, payload }, fetchOptions = { setSelectedItem: false }) => new Promise((resolve, reject) => {
        service.show(id, payload)
            .then(({ data }) => {
                if (fetchOptions.setSelectedItem) {
                    commit("setSelectedItem", data);
                }
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
    }),
    updateItem: ({ commit }, { id, payload }) => new Promise((resolve, reject) => {
        service.update(id, payload)
            .then(({ data }) => {
                commit("updateItem", data.data);
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
    }),
    storeItem: ({ commit }, { payload }) => new Promise((resolve, reject) => {
        service.store(payload)
            .then(({ data }) => {
                commit("storeItem", data.data);
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
    }),
});

export const createModule = ({
    options = {}, state = {}, getters = {}, mutations = {}, actions = {}, service = null, itemKey = null,
}) => ({
    ...options,
    namespaced: true,
    strict: true,
    state: generateState(state, itemKey),
    getters: generateGetters(getters),
    mutations: generateMutations(mutations, itemKey),
    actions: generateActions(actions, service),
});

export default {
    createModule,
};
