import BaseService from "../base-service";
import RequestClient from "../request-client";

class MessageService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "messages",
        });
    }

    assignToJob(messageId, payload) {
        return RequestClient.put(`/api/messages/${messageId}/job`, payload);
    }
}

export default new MessageService();
