import BaseService from "../base-service";
import RequestClient from "../request-client";

class UserService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "users",
        });
    }

    getIsMaximumIncompleteOrdersReached(userId) {
        return RequestClient.get(`/api/users/${userId}/get-is-maximum-orders-reached`);
    }

    getActivityLogs(userId, payload = {}) {
        return RequestClient.get(`/api/users/${userId}/activity-logs`, { params: payload });
    }

    getJobs(userId, payload = {}) {
        return RequestClient.get(`/api/users/${userId}/jobs`, { params: payload });
    }

    getNotifications(userId, payload = {}) {
        return RequestClient.get(`/api/users/${userId}/notifications`, { params: payload });
    }

    getOrders(userId, payload = {}) {
        return RequestClient.get(`/api/users/${userId}/orders`, { params: payload });
    }

    getOrganizations(userId, payload = {}) {
        return RequestClient.get(`/api/users/${userId}/organizations`, { params: payload });
    }

    getUserLogins(userId, payload = {}) {
        return RequestClient.get(`/api/users/${userId}/user-logins`, { params: payload });
    }

    getPayouts(userId, payload = {}) {
        return RequestClient.get(`/api/users/${userId}/payouts`, { params: payload });
    }

    updateSecurity(userId, payload) {
        return this.requestClient.put(`/api/users/${userId}/security`, payload);
    }

    updateGames(userId, payload) {
        return this.requestClient.put(`/api/users/${userId}/games`, payload);
    }

    updateNotifications(userId, payload) {
        return this.requestClient.put(`/api/users/${userId}/notifications`, payload);
    }

    updateOrganizations(userId, payload) {
        return this.requestClient.put(`/api/users/${userId}/organizations`, payload);
    }

    updatePayoutInformation(userId, payload) {
        return this.requestClient.put(`/api/users/${userId}/payout-information`, payload);
    }
}

export default new UserService();
