import BaseService from "../base-service";
import RequestClient from "../request-client";

class RoleService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "roles",
        });
    }

    /**
     * Get the users for a given role.
     */
    getUsers(payload = {}) {
        return RequestClient.get(`/api/roles/${payload.role_id}/users`, payload);
    }

    /**
     * Get the permissions for a given role.
     */
    getPermissions(payload = {}) {
        return RequestClient.get(`/api/roles/${payload.role_id}/permissions`, payload);
    }
}

export default new RoleService();
