import Service from "@/services/modules/job-service";
import { createModule } from "@/store/base-module";

export default createModule({
    service: Service,
    itemKey: "order_line_id",

    actions: {
        addAssistant({ commit }, payload = {}) {
            return new Promise((resolve, reject) => {
                const handleSuccess = (response) => {
                    commit("updateItem", response.data);
                    resolve(response);
                };

                const handleFailure = (error) => {
                    reject(error);
                };

                Service.addAssistant(payload)
                    .then(handleSuccess)
                    .catch(handleFailure);
            });
        },
        removeAssistant({ commit }, payload = {}) {
            return new Promise((resolve, reject) => {
                const handleSuccess = (response) => {
                    commit("updateItem", response.data);
                    resolve(response);
                };

                const handleFailure = (error) => {
                    reject(error);
                };

                Service.removeAssistant(payload)
                    .then(handleSuccess)
                    .catch(handleFailure);
            });
        },
        makePrimary({ commit }, payload = {}) {
            return new Promise((resolve, reject) => {
                const handleSuccess = (response) => {
                    commit("updateItem", response.data);
                    resolve(response);
                };

                const handleFailure = (error) => {
                    reject(error);
                };

                Service.makePrimary(payload)
                    .then(handleSuccess)
                    .catch(handleFailure);
            });
        },
    },
});
