/**
 * Users Routes
 */

import UserService from "@/services/modules/user-service";

const usersRoutes = [
    {
        path: "/users",
        name: "users",
        redirect: { name: "users.index" },
        component: () => import("@/components/ghost.vue"),
        meta: {
            auth: true,
            breadcrumb: true,
            title: "Users",
            permissions: [
                "users.read",
            ],
        },

        children: [
            {
                path: "/users",
                name: "users.index",
                component: () => import("@/views/panel/users/index.vue"),
                meta: {
                    auth: true,
                    title: "Users",
                },
            },
            {
                path: ":user/show",
                name: "users.show",
                redirect: { name: "users.show.summary" },
                component: () => import("@/views/panel/users/show/index.vue"),
                meta: {
                    auth: true,
                    breadcrumb: true,
                    title: (route) => new Promise((resolve, reject) => {
                        UserService.show(route.params.user)
                            .then((response) => resolve(response.data.data.name))
                            .catch((error) => reject(error));
                    }),
                },
                children: [
                    {
                        path: "summary",
                        name: "users.show.summary",
                        component: () => import("@/views/panel/users/show/summary.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Summary",
                        },
                    },
                    {
                        path: "activity-log",
                        name: "users.show.activity-log",
                        component: () => import("@/views/panel/users/show/activity-log.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Activity Log",
                        },
                    },
                    {
                        path: "jobs",
                        name: "users.show.jobs",
                        component: () => import("@/views/panel/users/show/jobs.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Jobs",
                        },
                    },
                    {
                        path: "notifications",
                        name: "users.show.notifications",
                        component: () => import("@/views/panel/users/show/notifications.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Notifications",
                        },
                    },
                    {
                        path: "orders",
                        name: "users.show.orders",
                        component: () => import("@/views/panel/users/show/orders.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Orders",
                        },
                    },
                    {
                        path: "payouts",
                        name: "users.show.payouts",
                        component: () => import("@/views/panel/users/show/payouts.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Payouts",
                        },
                    },
                    {
                        path: "games",
                        name: "users.show.games",
                        component: () => import("@/views/panel/users/show/games.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Games",
                        },
                    },
                    {
                        path: "organizations",
                        name: "users.show.organizations",
                        component: () => import("@/views/panel/users/show/organizations.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Organizations",
                        },
                    },
                    {
                        path: "payout-information",
                        name: "users.show.payout-information",
                        component: () => import("@/views/panel/users/show/payout-information.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Payout Information",
                        },
                    },
                    {
                        path: "security",
                        name: "users.show.security",
                        component: () => import("@/views/panel/users/show/security.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Security",
                        },
                    },
                    {
                        path: "user-logins",
                        name: "users.show.user-logins",
                        component: () => import("@/views/panel/users/show/user-logins.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "User Logins",
                        },
                    },
                ],
            },
        ],
    },
];

export default usersRoutes;
