import BaseService from "../base-service";
import RequestClient from "../request-client";

class PayoutService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "payouts",
        });
    }

    storeMultiple(payload) {
        return this.requestClient.post("/api/payouts/store/multiple", payload);
    }

    getCompletedJobReadyForPayout(payload) {
        return this.requestClient.get("/api/payouts/show/completed-jobs-for-payout", { params: payload });
    }

    getPayoutLines(id, payload) {
        return RequestClient.get(`/api/payouts/${id}/payout-lines`, { params: payload });
    }

    stats() {
        return {
            pending() {
                return {
                    getAdminFeeTotal(payload = null) {
                        return RequestClient.get("/api/stats/payouts/pending/admin-fee-total", { params: payload });
                    },

                    getFastPassTotal(payload = null) {
                        return RequestClient.get("/api/stats/payouts/pending/fast-pass-total", { params: payload });
                    },

                    getSherpaTotal(payload = null) {
                        return RequestClient.get("/api/stats/payouts/pending/sherpa-total", { params: payload });
                    },

                    getSystemTotal(payload = null) {
                        return RequestClient.get("/api/stats/payouts/pending/system-total", { params: payload });
                    },
                };
            },
        };
    }
}

export default new PayoutService();
