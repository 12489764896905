/**
 * Authentication Service
 */

import RequestClient from "../request-client";

const authService = {
    fetchUser: (payload) => RequestClient.get("/api/me", { params: { payload } }),
    impersonateUser: (userId, payload) => RequestClient.post(`/api/impersonation/${userId}`, payload),
};

export default authService;
