import BaseService from "../base-service";

class OrderService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "orders",
        });
    }

    getOrderFees(orderId, payload = {}) {
        return this.requestClient.get(`/api/orders/${orderId}/order-fees`, { params: payload });
    }

    getJobs(orderId, payload = {}) {
        return this.requestClient.get(`/api/orders/${orderId}/jobs`, { params: payload });
    }

    getMetaInformation(orderId, payload = {}) {
        return this.requestClient.get(`/api/orders/${orderId}/meta-information`, { params: payload });
    }
}

export default new OrderService();
