import ExampleItemModule from "./example-item-module";

export default {
    namespaced: true,

    state: {},

    getters: {},

    mutations: {},

    actions: {},

    modules: {
        exampleItemModule: ExampleItemModule,
    },
};
