/**
 * Conversations Routes
 */

const conversationsRoutes = [
    {
        path: "/conversations",
        name: "conversations",
        redirect: { name: "conversations.index" },
        component: () => import("@/components/ghost.vue"),
        meta: {
            auth: true,
            breadcrumb: true,
            title: "Conversations",
            permissions: [
                "conversations.read",
            ],
        },
        children: [
            {
                path: "/conversations",
                name: "conversations.index",
                component: () => import("@/views/panel/conversations/index.vue"),
                meta: {
                    auth: true,
                    title: "Conversations",
                },
                children: [
                    {
                        path: ":conversation/show",
                        name: "conversations.show",
                        component: () => import("@/views/panel/conversations/show/index.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Messages",
                        },
                    },
                ],
            },
        ],
    },
];

export default conversationsRoutes;
