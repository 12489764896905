import BaseService from "../base-service";

class MeService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "me",
        });
    }

    updateProfile(payload) {
        return this.requestClient.post("/api/me/update/profile", payload);
    }

    updateSettings(payload) {
        return this.requestClient.post("/api/me/update/settings", payload);
    }

    updatePayoutInformation(payload) {
        return this.requestClient.post("/api/me/update/payout-information", payload);
    }
}

export default new MeService();
