import Service from "@/services/modules/payout-service";
import { createModule } from "@/store/base-module";

export default createModule({
    service: Service,
    itemKey: "payout_id",
    actions: {
        storeMultiple({ commit }, payload = {}) {
            return new Promise((resolve, reject) => {
                const handleSuccess = (response) => {
                    commit("setItems", response.data);
                    resolve(response);
                };

                const handleFailure = (error) => {
                    reject(error);
                };

                Service.storeMultiple(payload)
                    .then(handleSuccess)
                    .catch(handleFailure);
            });
        },
    },
});
