import BaseService from "../base-service";

class ActivityLogService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "activity-logs",
        });
    }
}

export default new ActivityLogService();
