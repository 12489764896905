/**
 * Roles Routes
 */

import RoleService from "@/services/modules/role-service";

const rolesRoutes = [
    {
        path: "/roles",
        name: "roles",
        redirect: { name: "roles.index" },
        component: () => import("@/components/ghost.vue"),
        meta: {
            auth: true,
            breadcrumb: true,
            title: "Roles",
            permissions: [
                "roles.read",
            ],
        },

        children: [
            {
                path: "/roles",
                name: "roles.index",
                component: () => import("@/views/panel/roles/index.vue"),
                meta: {
                    auth: true,
                    title: "Roles",
                },
            },
            {
                path: ":role/show",
                name: "roles.show",
                redirect: { name: "roles.show.summary" },
                component: () => import("@/views/panel/roles/show/index.vue"),
                meta: {
                    auth: true,
                    breadcrumb: true,
                    title: (route) => new Promise((resolve, reject) => {
                        RoleService.show(route.params.role)
                            .then((response) => resolve(response.data.data.name))
                            .catch((error) => reject(error));
                    }),
                },
                children: [
                    {
                        path: "summary",
                        name: "roles.show.summary",
                        component: () => import("@/views/panel/roles/show/summary.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Summary",
                        },
                    },
                    {
                        path: "users",
                        name: "roles.show.users",
                        component: () => import("@/views/panel/roles/show/users.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Users",
                        },
                    },
                ],
            },
        ],
    },
];

export default rolesRoutes;
