const styleRoutes = [
    {
        path: "/styles/components",
        name: "styles.components",
        component: () => import("@/views/styles/components/index.vue"),
        meta: {
            auth: true,
        },
    },
];

export default styleRoutes;
