import Collect from "collect.js";
import Store from "@/store";
import RequestClient from "@/services/request-client";
import AuthService from "@/services/modules/auth-service";

export default {
    install: (Vue) => {
        Vue.mixin({
            data() {
                return {
                    isFormLoading: false,
                    isLoading: false,
                    isInitializing: true,
                };
            },
            methods: {
                toggleFormLoading(delay = 0) {
                    setTimeout(() => {
                        this.isFormLoading = !this.isFormLoading;
                    }, delay);
                },
                toggleLoading(delay = 0) {
                    setTimeout(() => {
                        this.isLoading = !this.isLoading;
                    }, delay);
                },
                toggleInitialize(delay = 0) {
                    setTimeout(() => {
                        this.isInitializing = !this.isInitializing;
                    }, delay);
                },
                parseFilters(rawFilters) {
                    return Object.values(rawFilters)
                        .filter((filter) => {
                            if (Array.isArray(filter)) {
                                return !!filter.length;
                            }

                            if (typeof filter === "boolean") {
                                return true;
                            }

                            return !!filter;
                        });
                },
            },
        });

        Vue.prototype.$auth = AuthService;

        Vue.prototype.$requestClient = RequestClient;

        Vue.prototype.$me = {
            fetchUser: (payload) => Store.dispatch("authModule/fetchUser", payload),
            getUser: () => Store.getters["authModule/getUser"],
            hasRole: (role = null) => {
                const user = Store.getters["authModule/getUser"];
                const roles = Collect(user.roles || []).pluck("role_id").toArray();

                return roles.includes(role);
            },
            hasPermission: (permission = null) => Store.getters["authModule/hasPermission"](permission),
        };
    },
};
