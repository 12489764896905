import AuthService from "@/services/modules/auth-service";
import Collect from "collect.js";
import Vue from "vue";

const generateState = () => ({
    user: null,
    permissions: [],
});

export default {
    namespaced: true,

    state: generateState(),

    getters: {
        getUser(currentState) {
            return currentState.user;
        },
        getPermissions(currentState) {
            return currentState.permissions;
        },
        hasPermission(currentState) {
            return (identifiers) => {
                if (typeof identifiers === "object") {
                    let hasPermission = true;

                    identifiers.forEach((identifier) => {
                        const statePermission = currentState.permissions.find((permission) => permission.permission_id === identifier || permission.name === identifier);

                        if (!statePermission) {
                            hasPermission = false;
                        }
                    });

                    return hasPermission;
                }

                const statePermission = currentState.permissions.find((permission) => permission.permission_id === identifiers || permission.name === identifiers);
                return !!(statePermission && statePermission.permission_id);
            };
        },
    },

    mutations: {
        resetState(currentState) {
            Object.assign(currentState, generateState());
        },
        setUser(currentState, user) {
            currentState.user = user;
        },
        setPermissions(currentState, user) {
            const permissions = Collect(user.permissions).toArray();
            Vue.set(currentState, "permissions", permissions);
        },
    },

    actions: {
        fetchUser({ commit }, payload = {}) {
            return new Promise((resolve, reject) => {
                const handleSuccess = (response) => {
                    const user = response.data;
                    commit("setUser", user);
                    commit("setPermissions", user);
                    resolve(response);
                };

                const handleFailure = (error) => {
                    reject(error);
                };

                AuthService.fetchUser(payload)
                    .then(handleSuccess)
                    .catch(handleFailure);
            });
        },
    },
};
