import Vue from "vue";
import _ from "lodash";
import Moment from "moment";

Vue.filter("customerName", (value) => {
    if (!value) return "";

    // Split the string.
    const str = value.toString().split(" ");

    if (str.length > 1) {
        return `${str[0]} ${str[1].charAt(0).toUpperCase()}.`;
    }

    return str[0];
});

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

Vue.filter("numberFormat", (value) => {
    if (!value) return "";

    const number = _.round(parseFloat(value), 2).toFixed(2);

    if (number < 0) {
        return `-$${numberWithCommas(number * -1)}`;
    }

    return `$${numberWithCommas(number)}`;
});

Vue.filter("dateToHuman", (value) => {
    if (!value) return "";

    const moment = Moment(value);

    // If message timestamp is within 2 minutes, return "just now".
    return moment.diff(Moment(), "minutes") > -2 ? "just now" : moment.fromNow();
});
