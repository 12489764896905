/**
 * Me Routes
 */

const meRoutes = [
    {
        path: "/me",
        name: "me",
        redirect: { name: "me.profile" },
        component: () => import("@/views/panel/me/index.vue"),
        meta: {
            auth: true,
            breadcrumb: true,
            title: "Me",
        },

        children: [
            {
                path: "profile",
                name: "me.profile",
                component: () => import("@/views/panel/me/profile.vue"),
                meta: {
                    auth: true,
                    breadcrumb: true,
                    title: "Profile",
                },
            },
            {
                path: "notifications",
                name: "me.notifications",
                component: () => import("@/views/panel/me/notifications.vue"),
                meta: {
                    auth: true,
                    breadcrumb: true,
                    title: "Notifications",
                },
            },
            {
                path: "payout-information",
                name: "me.payout-information",
                component: () => import("@/views/panel/me/payout-information.vue"),
                meta: {
                    auth: true,
                    breadcrumb: true,
                    title: "Payout Information",
                },
            },
            {
                path: "security",
                name: "me.security",
                component: () => import("@/views/panel/me/security.vue"),
                meta: {
                    auth: true,
                    breadcrumb: true,
                    title: "Security",
                },
            },
        ],
    },
];

export default meRoutes;
