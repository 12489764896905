import BaseService from "../base-service";

class OrderLineTipsService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "order-line-tips",
        });
    }
}

export default new OrderLineTipsService();
