/**
 * Reports Routes
 */

const reportsRoutes = [
    {
        path: "/reports",
        name: "reports",
        redirect: { name: "reports.index" },
        component: () => import("@/components/ghost.vue"),
        meta: {
            auth: true,
            breadcrumb: true,
            title: "reports",
            permissions: [
                "reports.read",
            ],
        },

        children: [
            {
                path: "/reports",
                name: "reports.index",
                redirect: { name: "reports.customer-message-report" },
                component: () => import("@/views/panel/reports/index.vue"),
                meta: {
                    auth: true,
                    title: "Reports",
                },
                children: [
                    {
                        path: "customer-message-report",
                        name: "reports.customer-message-report",
                        component: () => import("@/views/panel/reports/customer-message-report.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Customer Message Report",
                            description: "Messages sent by day.",
                        },
                    },
                    {
                        path: "orders-without-games-report",
                        name: "reports.orders-without-games-report",
                        component: () => import("@/views/panel/reports/orders-without-games-report.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Orders Without Games Report",
                            description: "A list of orders that have order lines whose products have no games.",
                        },
                    },
                    {
                        path: "tip-report",
                        name: "reports.tip-report",
                        component: () => import("@/views/panel/reports/tip-report.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Recent Tips Report",
                            description: "Tips sent from customers to Sherpas in the last 7 days.",
                        },
                    },
                    {
                        path: "review-report",
                        name: "reports.review-report",
                        component: () => import("@/views/panel/reports/review-report.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Recent Reviews Report",
                            description: "Customer reviews from completed orders.",
                        },
                    },
                    {
                        path: "order-status",
                        name: "reports.order-status",
                        component: () => import("@/views/panel/reports/order-status.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Order Status Report",
                            description: "Visualize job progress and cherry picking of jobs.",
                        },
                    },
                    {
                        path: "order-guest-customer-surveys-report",
                        name: "reports.order-guest-customer-surveys-report",
                        component: () => import("@/views/panel/reports/order-guest-customer-surveys-report.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "How Did You Hear About Us?",
                            description: "Report about lead sources.",
                        },
                    },
                ],
            },
        ],
    },
];

export default reportsRoutes;
