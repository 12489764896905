/**
 * Conversations Routes
 */

const conversationCommandsRoutes = [
    {
        path: "/conversation-commands",
        name: "conversation_commands",
        redirect: { name: "conversation_commands.index" },
        component: () => import("@/components/ghost.vue"),
        meta: {
            auth: true,
            breadcrumb: true,
            title: "Conversation Commands",
            permissions: [
                // "conversation-commands.read",
            ],
        },
        children: [
            {
                path: "/conversation-commands",
                name: "conversation_commands.index",
                component: () => import("@/views/panel/conversation-commands/index.vue"),
                meta: {
                    auth: true,
                    title: "Conversation Commands",
                },
                children: [
                    {
                        path: ":conversation_command/show",
                        name: "conversation_commands.show",
                        component: () => import("@/views/panel/conversation-commands/show/index.vue"),
                        meta: {
                            auth: true,
                            breadcrumb: true,
                            title: "Commands",
                        },
                    },
                ],
            },
        ],
    },
];

export default conversationCommandsRoutes;
