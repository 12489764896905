import BaseService from "../base-service";

class OrganizationService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "organizations",
        });
    }
}

export default new OrganizationService();
