import BaseService from "../base-service";

class PermissionService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "permissions",
        });
    }
}

export default new PermissionService();
