/**
 * Checks the user's permissions if they are allowed
 * to view the page.
 */

import Vue from "vue";

const checkPermission = (to, from, next) => {
    /**
     * Retrieve the permissions that the next route requires.
     */
    const permissions = to.matched.reduce((total, currentValue) => {
        if (currentValue.meta.permissions && currentValue.meta.permissions.length > 0) {
            total.push(...currentValue.meta.permissions);
        }

        return total;
    }, []);

    if (!Vue.prototype.$me.hasPermission(permissions)) {
        next({ name: "system.error", params: { code: 403 } });
    } else {
        next();
    }
};

const permissionMiddleware = (router) => {
    router.beforeEach((to, from, next) => {
        /**
         * Check if the route requires authentication.
         */
        const requiresAuth = (to.meta.auth === true) || (to.meta.auth === "block");

        /**
         * If the route requires authentication, check if the user is in the Vuex store.
         * Refresh the data if it's not there. Otherwise, do nothing.
         */
        if (!requiresAuth || to.name.includes("auth") || to.name.includes("system")) {
            next();
        } else if (!Vue.prototype.$me.getUser()) {
            Vue.prototype.$me.fetchUser()
                .then(() => {
                    checkPermission(to, from, next);
                })
                .catch(() => {
                    next({ name: "system.error", params: { code: 500 } });
                });
        } else {
            checkPermission(to, from, next);
        }
    });
};

export default permissionMiddleware;
