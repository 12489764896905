import BaseService from "../base-service";

class OrderLineUserRelationshipTypeService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "order-line-user-relationship-types",
        });
    }
}

export default new OrderLineUserRelationshipTypeService();
