const routes = [
    {
        path: "/system",
        name: "system",
        redirect: { name: "system.error", params: { code: 404 } },
        component: () => import("@/views/system/index.vue"),
        children: [
            {
                path: "/error/:code",
                name: "system.error",
                component: () => import("@/views/system/error.vue"),
            },
        ],
    },
    {
        path: "*",
        redirect: { name: "system.error", params: { code: 404 } },
    },
];

export default routes;
