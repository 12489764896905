import BaseService from "../base-service";

class GamePlatformService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "game-platforms",
        });
    }
}

export default new GamePlatformService();
