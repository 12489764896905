import BaseService from "../base-service";

class UserLoginService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "user-logins",
        });
    }
}

export default new UserLoginService();
