import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
// import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

export default {
    install: (app) => {
        app.prototype.$toast = ({ text = "", title = null, type = "info" }) => {
            app.swal.fire({
                text,
                title,
                icon: type,
                toast: true,
                position: "top-end",
                timer: 5500,
                showConfirmButton: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", app.swal.stopTimer);
                    toast.addEventListener("mouseleave", app.swal.resumeTimer);
                },
            });
        };
    },
};
