import BaseService from "../base-service";

class ImportOrderService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "import/import-orders",
        });
    }
}

export default new ImportOrderService();
