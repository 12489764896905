import Vue from "vue";
import Vuex from "vuex";
import AppModule from "@/store/modules/app-module";
import AuthModule from "@/store/modules/auth-module";
import GameModule from "@/store/modules/game-module";
import ActivityLogModule from "@/store/modules/activity-log-module";
import GamePlatformModule from "@/store/modules/game-platform-module";
import ImportOrderModule from "@/store/modules/import-order-module";
import ImportStatusModule from "@/store/modules/import-status-module";
import ImportSourceModule from "@/store/modules/import-source-module";
import ImportTypeModule from "@/store/modules/import-type-module";
import JobModule from "@/store/modules/job-module";
import MeModule from "@/store/modules/me-module";
import NoteModule from "@/store/modules/note-module";
import OrderModule from "@/store/modules/order-module";
import OrderLineModule from "@/store/modules/order-line-module";
import OrderLineTipsModule from "@/store/modules/order-line-tips-module";
import OrderLineStatusModule from "@/store/modules/order-line-status-module";
import OrderLineStatusHistoryModule from "@/store/modules/order-line-status-history-module";
import OrderStatusModule from "@/store/modules/order-status-module";
import OrderStatusHistoryModule from "@/store/modules/order-status-history-module";
import OrderUserModule from "@/store/modules/order-user-module";
import OrganizationModule from "@/store/modules/organization-module";
import OrderLineUserRelationshipTypeModule from "@/store/modules/order-line-user-relationship-type-module";
import PlayerEnvironmentModule from "@/store/modules/player-environment-module";
import ProductModule from "@/store/modules/product-module";
import PayoutModule from "@/store/modules/payout-module";
import PayoutLineModule from "@/store/modules/payout-line-module";
import PayoutStatusModule from "@/store/modules/payout-status-module";
import PermissionModule from "@/store/modules/permission-module";
import RoleModule from "@/store/modules/role-module";
import UserModule from "@/store/modules/user-module";
import UserLoginModule from "@/store/modules/user-login-module";
import UserTypeModule from "@/store/modules/user-type-module";
import ConversationModule from "@/store/modules/conversation-module";
import MessageModule from "@/store/modules/message-module";
import ExampleModule from "./modules/example-module";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        appModule: AppModule,
        authModule: AuthModule,
        exampleModule: ExampleModule,
        gameModule: GameModule,
        activityLogModule: ActivityLogModule,
        gamePlatformModule: GamePlatformModule,
        importOrderModule: ImportOrderModule,
        importStatusModule: ImportStatusModule,
        importSourceModule: ImportSourceModule,
        importTypeModule: ImportTypeModule,
        jobModule: JobModule,
        meModule: MeModule,
        noteModule: NoteModule,
        orderModule: OrderModule,
        orderLineModule: OrderLineModule,
        orderLineTipsModule: OrderLineTipsModule,
        orderLineStatusModule: OrderLineStatusModule,
        orderLineStatusHistoryModule: OrderLineStatusHistoryModule,
        orderLineUserRelationshipTypeModule: OrderLineUserRelationshipTypeModule,
        orderStatusModule: OrderStatusModule,
        orderStatusHistoryModule: OrderStatusHistoryModule,
        orderUserModule: OrderUserModule,
        organizationModule: OrganizationModule,
        playerEnvironmentModule: PlayerEnvironmentModule,
        productModule: ProductModule,
        payoutModule: PayoutModule,
        payoutLineModule: PayoutLineModule,
        payoutStatusModule: PayoutStatusModule,
        permissionModule: PermissionModule,
        roleModule: RoleModule,
        userModule: UserModule,
        userLoginModule: UserLoginModule,
        userTypeModule: UserTypeModule,
        conversationModule: ConversationModule,
        messageModule: MessageModule,
    },
});

export default store;
