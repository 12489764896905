/**
 * Payouts Routes
 */

const payoutsRoutes = [
    {
        path: "/payouts",
        name: "payouts",
        redirect: { name: "payouts.index" },
        component: () => import("@/components/ghost.vue"),
        meta: {
            auth: true,
            title: "Payouts",
            breadcrumb: true,
            permissions: [
                "payouts.read",
            ],
        },

        children: [
            {
                path: "/payouts",
                name: "payouts.index",
                component: () => import("@/views/panel/payouts/index.vue"),
                meta: {
                    auth: true,
                    title: "Payouts",
                },
            },
            {
                path: "/payouts/create",
                name: "payouts.create",
                component: () => import("@/views/panel/payouts/create.vue"),
                meta: {
                    auth: true,
                    title: "Create Payout",
                    breadcrumb: true,
                    permissions: [
                        "payouts.create",
                    ],
                },
            },
            {
                path: ":payout/show",
                name: "payouts.show",
                redirect: { name: "payouts.show.summary" },
                component: () => import("@/views/panel/payouts/show/index.vue"),
                meta: {
                    auth: true,
                    breadcrumb: true,
                    title: (route) => new Promise((resolve) => {
                        resolve(route.params.payout);
                    }),
                },
                children: [
                    {
                        path: "summary",
                        name: "payouts.show.summary",
                        component: () => import("@/views/panel/payouts/show/summary.vue"),
                        meta: {
                            auth: true,
                            title: "Summary",
                            breadcrumb: true,
                        },
                    },
                ],
            },
        ],
    },
];

export default payoutsRoutes;
