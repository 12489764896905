import BaseService from "../base-service";
import RequestClient from "../request-client";

class ConversationService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "conversations",
        });
    }

    getMessages(conversationId, payload) {
        return RequestClient.get(`/api/conversations/${conversationId}/messages`, { params: payload });
    }

    storeMessage(conversationId, payload) {
        return RequestClient.post(`/api/conversations/${conversationId}/messages`, payload);
    }
}

export default new ConversationService();
