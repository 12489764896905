/* eslint-disable camelcase */
/**
 * Vue oauth
 * @author 5align <support@5align.com>
 * @todo Write a description.
 */

import axios from "axios";

const DefaultOptions = {
    baseURL: "",
    targets: {
        api: {
            login: "/oauth/token",
            logout: "/logout",
            register: "/register",
            forgotPassword: "/api/forgot-password/email",
            resetPassword: "/api/forgot-password/update",
            refreshToken: "/oauth/token",
        },
        pages: {
            login: "/login",
            true: "/dashboard",
        },
    },
    storage: {
        store({
            access_token, expires_in, refresh_token, token_type,
        }) {
            // Add minutes to date.
            function addMinutes(date, minutes) {
                return new Date(date.getTime() + minutes * 60000);
            }
            const expires_date = addMinutes(new Date(), expires_in);

            // Store items.
            localStorage.setItem("access_token", access_token);
            localStorage.setItem("expires_in", expires_in);
            localStorage.setItem("refresh_token", refresh_token);
            localStorage.setItem("token_type", token_type);
            localStorage.setItem("expires_date", expires_date);

            // Return data.
            return {
                access_token,
                expires_in,
                refresh_token,
                token_type,
                expires_date,
            };
        },
    },
};

const VueOAuth = {
    async install(Vue, UserOptions) {
        const Options = {
            baseURL: UserOptions.baseURL || "",
            targets: {
                ...DefaultOptions.targets,
                ...UserOptions.targets,
            },
            storage: {
                ...DefaultOptions.storage,
                ...UserOptions.storage,
            },
            router: UserOptions.router,
        };

        const Router = Options.router;
        Router.beforeEach((to, from, next) => {
            /**
              * Check Auth Strategy.
              * True - Require user to be authenticated.
              * False - Don't do anything.
              * Block - Redirect authenticated users.
              */

            const strategy = to.meta.auth;
            const { $oauth } = Vue.prototype;

            // False - Don't do anything.
            if (!strategy) next();

            else {
                // Check if the token is expired.
                const { expired } = $oauth.checkExpiry();

                const handleSuccess = () => {
                    // Block - Redirect authenticated users.
                    if (strategy === "block") next(Options.targets.pages.true);

                    // True - Require user to be authenticated.
                    else next();
                };

                const handleFailure = () => {
                    if (strategy !== "block") {
                        localStorage.removeItem("access_token");
                        localStorage.removeItem("expires_in");
                        localStorage.removeItem("refresh_token");
                        localStorage.removeItem("token_type");
                        localStorage.removeItem("expires_date");
                        next(Options.targets.pages.login);
                    } else next();
                };

                // If the token is expired, attempt to renew it.
                if (expired) {
                    $oauth.checkAuth()
                        .then(handleSuccess)
                        .catch(handleFailure);
                } else {
                    // Don't renew the token if it isn't expired yet.
                    handleSuccess();
                }
            }
        });

        Vue.prototype.$oauth = {
            /**
              * Login
              * @todo Write a description.
              */
            login({ username, password }) {
                return new Promise((resolve, reject) => {
                    axios.get(`${Options.baseURL}/api/meta/password-grant-client`)
                        .then((response) => axios.post(Options.baseURL + Options.targets.api.login, {
                            grant_type: "password",
                            client_id: response.data.id,
                            client_secret: response.data.secret,
                            scope: "*",
                            username,
                            password,
                        }))
                        .then((response) => {
                            const config = {
                                headers: {
                                    Authorization: `Bearer ${response.data.access_token}`,
                                },
                            };

                            axios.get(`${Options.baseURL}/api/check-login-enabled`, config)
                                .then(() => {
                                    Options.storage.store(response.data);
                                    resolve(response);
                                })
                                .catch((error) => reject(error));
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            },

            /**
              * Forgot Password
              * @todo Write a description.
              */
            forgotPassword({ email }) {
                return new Promise((resolve, reject) => {
                    const payload = {
                        email,
                    };

                    const handleSuccess = (response) => {
                        resolve(response);
                    };

                    const handleFailure = (error) => {
                        reject(error);
                    };

                    axios
                        .post(Options.baseURL + Options.targets.api.forgotPassword, payload)
                        .then(handleSuccess)
                        .catch(handleFailure);
                });
            },

            /**
              * Logout
              * @todo Write a description.
              */
            logout(api = true) {
                return new Promise((resolve, reject) => {
                    const target = Options.baseURL + Options.targets.api.logout;

                    const clearData = () => {
                        localStorage.removeItem("access_token");
                        localStorage.removeItem("expires_in");
                        localStorage.removeItem("refresh_token");
                        localStorage.removeItem("token_type");
                        localStorage.removeItem("expires_date");
                    };

                    const handleSuccess = (response) => {
                        clearData();
                        resolve(response);
                    };

                    const handleFailure = (error) => {
                        clearData();
                        reject(error);
                    };

                    if (api) {
                        axios.post(target)
                            .then(handleSuccess)
                            .catch(handleFailure);
                    } else {
                        clearData();
                        resolve({ success: true });
                    }
                });
            },

            /**
              * Register
              * @todo Write a description.
              */
            register({
                name, email, password, password_confirmation, stripe, code, discord_username,
            }) {
                return new Promise((resolve, reject) => {
                    const payload = {
                        name,
                        email,
                        password,
                        password_confirmation,
                        stripe,
                        code,
                        discord_username,
                    };

                    const handleSuccess = (response) => {
                        resolve(response);
                    };

                    const handleFailure = (error) => {
                        reject(error);
                    };

                    axios
                        .post(Options.baseURL + Options.targets.api.register, payload)
                        .then(handleSuccess)
                        .catch(handleFailure);
                });
            },

            /**
              * Remind Password
              * @todo Write a description.
              */
            remind({ email }) {
                return new Promise((resolve, reject) => {
                    const payload = {
                        email,
                    };

                    const handleSuccess = (response) => {
                        resolve(response);
                    };

                    const handleFailure = (error) => {
                        reject(error);
                    };

                    axios
                        .post(Options.baseURL + Options.targets.api.remind, payload)
                        .then(handleSuccess)
                        .catch(handleFailure);
                });
            },

            /**
              * Reset Password
              * @todo Write a description.
              */
            resetPassword({
                token, email, password, password_confirmation,
            }) {
                return new Promise((resolve, reject) => {
                    const payload = {
                        token,
                        email,
                        password,
                        password_confirmation,
                    };

                    const handleSuccess = (response) => {
                        resolve(response);
                    };

                    const handleFailure = (error) => {
                        reject(error);
                    };

                    axios
                        .post(Options.baseURL + Options.targets.api.resetPassword, payload)
                        .then(handleSuccess)
                        .catch(handleFailure);
                });
            },

            /**
              * Refresh Token
              * @todo Write a description.
              */
            refreshToken() {
                return new Promise((resolve, reject) => {
                    axios.get(`${Options.baseURL}/api/meta/password-grant-client`)
                        .then((response) => axios.post(Options.baseURL + Options.targets.api.refreshToken, {
                            grant_type: "refresh_token",
                            client_id: response.data.id,
                            client_secret: response.data.secret,
                            scope: "*",
                            refresh_token: localStorage.getItem("refresh_token"),
                        }))
                        .then((response) => {
                            const config = {
                                headers: {
                                    Authorization: `Bearer ${response.data.access_token}`,
                                },
                            };

                            axios.get(`${Options.baseURL}/api/check-login-enabled`, config)
                                .then(() => {
                                    Options.storage.store(response.data);
                                    resolve(response);
                                })
                                .catch((error) => reject(error));
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            },

            /**
              * Check Expiry
              * @todo Write a description.
              */
            checkExpiry() {
                const expiry = () => new Date(localStorage.getItem("expires_date"));
                const now = () => new Date();
                const past = expiry() < now();
                if (expiry().getMinutes === now().getMinutes() || past) {
                    return { expired: true };
                }
                return { expired: false };
            },

            /**
              * Check Authentication
              * @todo Write a description.
              */
            checkAuth() {
                return new Promise((resolve, reject) => {
                    const data = () => ({
                        access_token: localStorage.getItem("access_token"),
                        expires_in: localStorage.getItem("expires_in"),
                        refresh_token: localStorage.getItem("refresh_token"),
                        token_type: localStorage.getItem("token_type"),
                        expires_date: localStorage.getItem("expires_date"),
                    });

                    // Check data integrity.
                    let hasNull = false;
                    Object.keys(data()).forEach((key) => {
                        const item = data()[key];
                        if (!item) hasNull = true;
                    });

                    // No Data
                    if (hasNull) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        reject({ message: "No authentication data is present." });
                    } else {
                        this.refreshToken()
                            .then(() => {
                                resolve({ message: "User is logged in." });
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }
                });
            },
        };
    },
};

export default VueOAuth;
