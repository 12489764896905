import Vue from "vue";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import PortalVue from "portal-vue";
import VTooltip from "v-tooltip";
import Datepicker from "vuejs-datepicker";
import VueOAuth from "@/plugins/auth";
import Router from "@/router";
import VueCompositionAPI from "@vue/composition-api";
import VueToast from "@/plugins/toast";
import EventBus from "./plugins/event-bus";
import ComponentUtils from "./plugins/component-utils";

const bugsnagKey = process.env.VUE_APP_BUGSNAG_KEY;

if (bugsnagKey) {
    Bugsnag.start({
        apiKey: bugsnagKey,
        plugins: [new BugsnagPluginVue()],
        releaseStage: process.env.VUE_APP_ENV || process.env.NODE_ENV,
    });

    const bugsnagVue = Bugsnag.getPlugin("vue");
    bugsnagVue.installVueErrorHandler(Vue);
} else {
    console.error("Bugsnag key not found.");
}

Vue.use(PortalVue);
Vue.use(VTooltip);
Vue.use(Datepicker);
Vue.use(EventBus);
Vue.use(ComponentUtils);
Vue.use(VueCompositionAPI);
Vue.use(VueToast);

Vue.use(VueOAuth, {
    baseURL: process.env.VUE_APP_API_URL,
    targets: {
        api: {
            login: "/oauth/token",
            logout: "/logout",
            register: "/api/register",
            forgotPassword: "/api/forgot-password/email",
            resetPassword: "/api/forgot-password/update",
            refreshToken: "/oauth/token",
        },
        pages: {
            login: "/login",
            true: "/dashboard",
        },
    },
    router: Router,
});
