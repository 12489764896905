import BaseService from "../base-service";

class UserTypeService extends BaseService {
    /**
     * Create a new Service instance.
     */
    constructor() {
        super({
            endpoint: "user-types",
        });
    }
}

export default new UserTypeService();
